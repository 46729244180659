<script setup lang="ts">
  import { MeUser } from '@/api/interfaces/User'

  import { Nullable } from '@algorh/shared'

  import { AlgAvatar } from '@algorh/ui'

  interface Props {
    readonly user?: Nullable<MeUser>
  }

  const props = defineProps<Props>()
</script>

<template>
  <div class="user-infos">
    <AlgAvatar
      class="avatar"
      size="s"
    />
    <span
      v-if="props.user"
      class="details"
    >
      <span class="name">
        {{ props.user.full_name }}
      </span>
      <span
        v-if="props.user.roles.length"
        class="category"
      >
        <span
          v-for="(item, key) in props.user.roles"
          :key="item.id"
        >
          {{ item.label }}<span v-if="props.user.roles.length > key + 1">, </span>
        </span>
      </span>
    </span>
  </div>
</template>

<style lang="scss" scoped>
  .user-infos {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--alg-spacing-s);

    .avatar {
      flex: 0 0 auto;
    }

    .details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .name {
        display: block;
        color: var(--color-grey-100);
        font-size: var(--size-s);
        font-weight: 700;
        line-height: 20px;
        word-break: break-word;
      }

      .category {
        display: block;
        color: var(--color-grey-70);
        font-size: var(--size-s);
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
</style>
