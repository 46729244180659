import { UserPermissions } from '@/api/interfaces/User'
import { RouteRecordRaw } from 'vue-router'
import AppLayout from '@/layouts/app/App.vue'
import { DTF, dayjs } from '@algorh/shared'

const customerAdviserRouter: RouteRecordRaw[] = [
  {
    name: 'calendar',
    path: 'calendar',

    meta: {
      redirectable: true,
      weight: 100,
      permissions: [UserPermissions.ACCESS_CALENDAR],
      layout: AppLayout,
      breadcrumb: 'calendar.My planning',
    },
    redirect: () => ({
      name: 'calendar-date',
      params: {
        // Calendrier s'ouvre tjs sur le lundi de la semaine en cours
        date: dayjs().startOf('week').format(DTF.DATE),
      },
    }),
    children: [
      {
        path: ':date',
        name: 'calendar-date',
        component: () => import('@/sections/customer-adviser/calendar/CalendarPage.vue'),
      },
    ],
  },
]

export { customerAdviserRouter }
