import AppLayout from '@/layouts/app/App.vue'
import { RouterView } from 'vue-router'
const stockExchangeRouter = [
  {
    path: 'stock-exchange',
    name: 'stock-exchange',
    redirect: { name: 'stock-exchange-list' },
    component: RouterView,
    meta: {
      layout: AppLayout,
      breadcrumb: 'stockExchange.Stock exchange',
    },
    children: [
      {
        path: '',
        name: 'stock-exchange-list',
        component: () => import('@/sections/stock-exchange/StockExchange.vue'),
        meta: {
          layout: AppLayout,
        },
      },
      {
        path: 'day-off',
        name: 'stock-exchange-day-off',
        redirect: { name: 'stock-exchange-day-off-list' },
        meta: {
          layout: AppLayout,
          breadcrumb: 'stockExchange.Days off',
        },
        component: () => import('@/sections/stock-exchange/days-off/DaysOff.vue'),
        children: [
          {
            path: 'list',
            name: 'stock-exchange-day-off-list',
            component: () => import('@/sections/stock-exchange/days-off/DaysOffList.vue'),
          },
          {
            path: 'request',
            name: 'stock-exchange-day-off-request',
            component: () => import('@/sections/stock-exchange/days-off/DaysOffExchangeRequest.vue'),
          },
          {
            path: 'requests-and-offers',
            name: 'stock-exchange-day-off-requests-and-offers',
            component: () =>
              import('@/sections/stock-exchange/days-off/requests-and-offers/RequestsAndOffers.vue'),
          },
        ],
      },
      {
        path: 'slot',
        name: 'stock-exchange-slot',
        redirect: { name: 'stock-exchange-slot-list' },
        meta: {
          layout: AppLayout,
          breadcrumb: 'stockExchange.Activity slots',
        },
        component: () => import('@/sections/stock-exchange/slots/Slots.vue'),
        children: [
          {
            path: 'list',
            name: 'stock-exchange-slot-list',
            component: () => import('@/sections/stock-exchange/slots/SlotsList.vue'),
          },
          {
            path: 'request',
            name: 'stock-exchange-slot-request',
            component: () => import('@/sections/stock-exchange/slots/SlotsExchangeRequest.vue'),
          },
          {
            path: 'requests-and-offers',
            name: 'stock-exchange-slot-requests-and-offers',
            component: () =>
              import('@/sections/stock-exchange/slots/requests-and-offers/RequestsAndOffers.vue'),
          },
        ],
      },
    ],
  },
]

export { stockExchangeRouter }
