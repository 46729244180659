import { useQuery } from '@tanstack/vue-query'
import { computed } from 'vue'
import { MeApiService } from '@/api/me.service'
import { SchedulingSubdivisionsApiService } from '@/api/scheduling'
import { UserPermissions } from '@/api/interfaces/User'
import { CommonApiService } from '@/api/common.service'
import { ActivityCategory } from '@algorh/shared'

const staleTime = 24 * 60 * 60 * 1000 // 24H
export function useAppInit() {
  // Computed
  const permissions = computed(() => me.value?.data?.permissions ?? null)

  const isAuthenticated = computed(() => me.value ? me.value.data !== null : false)

  const canFetchOperationalSubdivisions = computed(() =>
    permissions.value?.[UserPermissions.ACCESS_SCHEDULING] ?? false,
  )

  const isLoadingApp = computed(() => isLoadingProjectActivities || isLoadingMe || isLoadingOperationalSubdivisions)
  const meId = computed(() => me.value?.data?.id ?? null)

  const projectActivitiesEditable = computed(() => projectActivities.value.data
    ?.filter(({ selectable, raw_planifiable, manually_planifiable }) => [manually_planifiable, selectable, raw_planifiable].some((v) => v)) ?? [])

  const projectActivitiesAutomaticallySchedulable = computed(() => projectActivities.value.data
    ?.filter(({ schedulable, raw_planifiable }) => [schedulable, raw_planifiable].some((v) => v)) ?? [])

  const projectActivitiesProduction = computed(() => projectActivities.value.data
    ?.filter(({ category }) => category === ActivityCategory.PRODUCTION) ?? [])

  const projectActivitiesNonProduction = computed(() => projectActivities.value.data
    ?.filter(({ category }) => category === ActivityCategory.NON_PRODUCTION) ?? [])

  // Composable
  const { data: me, isLoading: isLoadingMe } = useQuery({
    enabled: true,
    queryKey: ['me'],
    queryFn: MeApiService.getMe,
    staleTime,
  })

  const {
    data: projectActivities,
    isFetching: isLoadingProjectActivities,
  } = useQuery({
    queryKey: [meId, 'common', 'project-activities'],
    queryFn: CommonApiService.getProjectActivities,
    staleTime,
    initialData: { data: [] },
    initialDataUpdatedAt: 0,
  })

  const { data: operationalSubdivisions, isLoading: isLoadingOperationalSubdivisions } = useQuery({
    enabled: canFetchOperationalSubdivisions,
    queryKey: ['scheduling', 'operational-subdivisions'],
    queryFn: SchedulingSubdivisionsApiService.getSubdivisions,
    staleTime,
    initialData: { data: [] },
    initialDataUpdatedAt: 0,
  })

  return {
    projectActivitiesAutomaticallySchedulable,
    projectActivitiesEditable,
    projectActivitiesNonProduction,
    projectActivitiesProduction,
    operationalSubdivisions,
    isAuthenticated,
    projectActivities,
    me,
    permissions,
    isLoadingApp,
  }
}
