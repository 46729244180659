<script setup lang="ts">
  import { onBeforeMount } from 'vue'

  import { FloatingPlacement, useFloating } from '@algorh/shared'

  interface Props {
    readonly allowEscape?: boolean
    readonly allowFlip?: boolean
    readonly fullWidth?: boolean
    readonly offset?: number
    readonly placement?: FloatingPlacement
    readonly strategy?: 'fixed' | 'absolute'
  }

  const props = withDefaults(defineProps<Props>(), {
    allowEscape: true,
    allowFlip: true,
    fullWidth: false,
    offset: 8,
    placement: () => 'top',
    strategy: 'fixed',
  })

  const emit = defineEmits<{
    (e: 'open'): void
    (e: 'close'): void
  }>()

  const { open, isOpen, onClose, floating, reference, computedPosition } = useFloating(props.placement, false, 8)

  defineExpose({
    open,
    close,
    toggle,
  })

  // Methods
  function onOpen() {
    open()

    emit('open')
  }

  function close() {
    isOpen.value = false

    emit('close')
  }

  function toggle() {
    isOpen.value ? close() : onOpen()
  }

  onBeforeMount(() => {
    onClose.value = close
  })
</script>

<template>
  <div
    class="popper-wrapper"
    :class="{
      'full-width': props.fullWidth,
    }"
  >
    <div
      ref="reference"
      class="popper-reference"
    >
      <slot
        name="reference"
        v-bind="{ isOpen, open, close, onOpen, toggle }"
      />
    </div>
    <div
      v-if="isOpen"
      ref="floating"
      class="popper"
      :style="{
        position: 'fixed',
        top: `${computedPosition?.y}px`,
        left: `${computedPosition?.x}px`,
        width: props.fullWidth && reference ? `${reference.clientWidth}px` : 'auto',
      }"
    >
      <slot
        v-if="isOpen"
        name="content"
        v-bind="{ isOpen, close, onOpen, toggle }"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.popper-wrapper {
  &.full-width {
    width: 100%;
  }

  .popper-reference {
    position: relative;
  }

  .popper {
    z-index: 1000;
  }
}
</style>
