<script setup lang="ts">
  import { computed, CSSProperties } from 'vue'

  import { lang } from '@/plugins/langjs'

  export interface Legend {
    color: string
    label: string
  }

  interface Props {
    readonly direction?: 'vertical' | 'horizontal'
    readonly columns?: number
    readonly legends?: Legend[]
  }

  const props = withDefaults(defineProps<Props>(), {
    direction: 'vertical',
    columns1: 1,
    legends: () => [],
  })

  const style = computed<CSSProperties>(() => {
    if (props.direction === 'vertical') {
      return {
        display: 'grid',
        gridTemplateColumns: `repeat(${props.columns}, 1fr)`,
      }
    }

    if (props.direction === 'horizontal') {
      return {
        display: 'flex',
        flexDirection: 'row',
      }
    }

    return {}
  })
</script>

<template>
  <div
    class="caption-wrapper"
    :style="style"
  >
    <div
      v-for="(legend, key) in props.legends"
      :key="key"
      class="caption"
    >
      <i
        class="dot"
        :style="{ backgroundColor: legend.color }"
      />
      {{ lang.trans(legend.label) }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .caption-wrapper {
    align-items: center;
    justify-content: center;
    margin: var(--alg-spacing-m) 0;
    color: var(--alg-color-text-primary);
    font-size: var(--alg-font-size-s);
    gap: var(--alg-spacing-s);

    .caption {
      display: flex;
      align-items: center;
      gap: var(--alg-spacing-xs);

      .dot {
        width: 8px;
        height: 8px;
        border-radius: 8px;
      }
    }
  }
</style>
