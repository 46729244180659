import { defineStore } from 'pinia'

import { computed, ref } from 'vue'

import { RouteLocationRaw } from 'vue-router'

import { DTF, Nullable } from '@algorh/shared'

import { CalendarCustomerAdvisersApiService } from '@/api/calendar'

import { CalendarWorkingSlot } from '@/api/interfaces/Calendar'
import { UserNotification } from '@/api/interfaces/User'

import { MeApiService } from '@/api/me.service'

import { FetchError } from '@/utils/fetch'
import { dayjs } from '@/utils/dayjs'

import { useAppInit, useAlertsStore, useNotifications } from '@/composables'

import { getActivityChangeNotification } from './activityChangeNotification'

export const useNotificationStore = defineStore('notificationStore', () => {
  const { projectActivities } = useAppInit() // @TODO voir si bien filtré

  const { setAlert } = useAlertsStore()

  const { getNotifications, showNotification } = useNotifications()

  // Refs
  const notifications = ref<UserNotification[]>([])
  const notificationsLoading = ref<boolean>(false)
  const notificationsError = ref<FetchError>()
  const readNotificationsLoading = ref<string[]>([])
  const readNotificationsError = ref<FetchError>()
  const displayNotificationsWarning = ref<boolean>(true)
  const scheduledNotifications = ref<string[]>([])
  const readActivityChangeNotifications = ref<string[]>([])
  const workingSchedule = ref<Nullable<CalendarWorkingSlot[]>>(null)

  // Computed
  const allNotifications = computed(() => notifications.value)
  const activityChangeNotifications = computed(
    () =>
      notifications.value.filter(
        (notification) =>
          notification.content.type === 'activity_change'
          && !readActivityChangeNotifications.value.includes(notification.id),
      ) || [],
  )

  const readNotifications = computed(() =>
    notifications.value.filter((notification) => notification.read_at !== null),
  )

  const unreadNotifications = computed(() =>
    notifications.value.filter(
      (notification) =>
        notification.read_at === null
        && !readActivityChangeNotifications.value.includes(notification.id),
    ),
  )

  const readPlanningUpdateNotifications = computed(() =>
    readNotifications.value.filter(
      (notification) => notification.content.type === 'planning_update',
    ),
  )

  const unreadPlanningUpdateNotifications = computed(() =>
    unreadNotifications.value.filter(
      (notification) => notification.content.type === 'planning_update' && !notification.read_at,
    ),
  )

  const readExchangeNotifications = computed(() =>
    readNotifications.value.filter((notification) => notification.content.type === 'exchange'),
  )

  const unreadExchangeNotifications = computed(() =>
    unreadNotifications.value.filter((notification) => notification.content.type === 'exchange'),
  )

  const unreadNotificationsLoading = computed(() => readNotificationsLoading.value)

  // Methods
  async function fetchNotifications(redirect?: (to: RouteLocationRaw) => void) {
    try {
      if (!projectActivities.value.data) {
        return
      }
      notificationsError.value = undefined
      notificationsLoading.value = true

      const { data: notifs } = await MeApiService.getNotifications()

      if (workingSchedule.value === null || projectActivities.value === null) {
        await fetchWorkingSchedule()
        return
      }

      const unreadPlanningUpdateNotifications = notifs?.filter(
        (notif) =>
          notif.content.type === 'planning_update'
          && notif.read_at === null
          && !unreadNotifications.value.some((unreadNotif) => unreadNotif.id === notif.id),
      )

      if (unreadPlanningUpdateNotifications?.length) {
        await fetchWorkingSchedule()
      }

      unreadPlanningUpdateNotifications?.forEach(async (notif) => {
        const timestamp = dayjs(notif.created_at).valueOf()

        try {
          await showNotification(notif.content.subject, {
            tag: `unread-planning-update-${timestamp}`,
            body: notif.content.message,
            data: {
              redirectUrl: `/calendar/${dayjs().startOf('week').format(DTF.DATE)}`,
            },
          })
        } catch (e) {
          console.error(e)
        }

        setAlert(
          {
            variant: 'info',
            title: notif.content.subject,
            message: notif.content.message,
            autoHide: false,
            closable: true,
            onClose: async () => {
              await markAsReadNotifications([notif.id])

              try {
                const notifications = await getNotifications(`unread-planning-update-${timestamp}`)

                notifications.forEach((notification) => {
                  notification.close()
                })
              } catch (e) {
                console.error(e)
              }
            },
          },
          notif.id,
        )
      })

      const activityChange = getActivityChangeNotification(
        workingSchedule.value,
        projectActivities.value.data,
      )

      if (activityChange && !notifications.value.map((n) => n.id).includes(activityChange.id)) {
        notifications.value = [...(notifs || []), activityChange]

        const timestamp = dayjs(activityChange.created_at).valueOf()

        const redirectUrl = `/calendar/${dayjs().startOf('week').format(DTF.DATE)}`

        try {
          await showNotification(activityChange.content.subject, {
            tag: `activity-change-${timestamp}`,
            body: activityChange.content.message,
            data: { redirectUrl },
          })
        } catch (e) {
          console.error(e)
        }

        markAsReadActivityChangeNotifications([activityChange.id])

        if (redirect) {
          redirect(redirectUrl)
        }

        setAlert({
          variant: 'info',
          title: activityChange.content.subject,
          message: activityChange.content.message,
          autoHide: false,
          closable: true,
          onClose: async () => {
            markAsReadActivityChangeNotifications([activityChange.id])

            try {
              const notifications = await getNotifications(`activity-change-${timestamp}`)

              notifications.forEach((notification) => {
                notification.close()
              })
            } catch (e) {
              console.error(e)
            }
          },
        })

        return
      }

      notifications.value = [
        ...(notifs || []),
        ...notifications.value.filter((n) => n.content.type === 'activity_change'),
      ]
    } catch (e) {
      notificationsError.value = e as FetchError
    } finally {
      notificationsLoading.value = false
    }
  }

  async function fetchWorkingSchedule() {
    const { data } = await CalendarCustomerAdvisersApiService.getScheduleByDate(
      dayjs().startOf('week').format(DTF.DATE),
    )

    if (!data) return

    workingSchedule.value = data.schedule.working_schedule
    // projectActivities.value = data.project_activities
    // @TODO si on doit mettre seulement les editable et supprimer l'entrée dans cette route
  }

  async function markAsReadNotifications(uuids: string[]) {
    const filteredUuids = uuids.filter((uuid) => !readNotificationsLoading.value.includes(uuid))

    try {
      readNotificationsError.value = undefined
      readNotificationsLoading.value = [...readNotificationsLoading.value, ...filteredUuids]
      await MeApiService.putNotificationsAsRead(filteredUuids)

      notifications.value = notifications.value.map((notification): UserNotification => {
        if (filteredUuids.includes(notification.id)) {
          notification.read_at = dayjs().format(DTF.DATETIME)
        }
        return notification
      })
    } catch (e) {
      readNotificationsError.value = e as FetchError
    } finally {
      readNotificationsLoading.value = []
    }
  }

  function markAsReadActivityChangeNotifications(uuids: string[]) {
    readActivityChangeNotifications.value = [...uuids, ...readActivityChangeNotifications.value]
  }

  async function deleteNotifications(uuids: string[]) {
    try {
      readNotificationsError.value = undefined
      readNotificationsLoading.value = [...readNotificationsLoading.value, ...uuids]
      await MeApiService.deleteNotifications(uuids)
      await fetchNotifications()
    } catch (e) {
      readNotificationsError.value = e as FetchError
    } finally {
      readNotificationsLoading.value = []
    }
  }

  function hideNotificationsWarning() {
    displayNotificationsWarning.value = false
  }

  return {
    // State
    notifications,
    notificationsLoading,
    notificationsError,
    readNotificationsError,
    readNotificationsLoading,
    displayNotificationsWarning,
    scheduledNotifications,
    // Getters
    readNotifications,
    unreadNotifications,
    allNotifications,
    readPlanningUpdateNotifications,
    unreadPlanningUpdateNotifications,
    readExchangeNotifications,
    unreadExchangeNotifications,
    unreadNotificationsLoading,
    activityChangeNotifications,
    // Actions
    fetchNotifications,
    fetchWorkingSchedule,
    markAsReadNotifications,
    markAsReadActivityChangeNotifications,
    deleteNotifications,
    hideNotificationsWarning,
  }
})
