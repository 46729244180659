import { createRouter, createWebHistory } from 'vue-router'

import { navigationManager } from './navigationManager'

import { authRouter } from './sections/auth'
import { documentationRouter } from './sections/documentation'
import { stockExchangeRouter } from './sections/stock-exchange'
import { projectsRouter } from './sections/projects'
import { schedulingRouter } from './sections/scheduling'
import { settingsRouter } from './sections/settings'
import { supportRouter } from './sections/support'
import { usersRouter } from './sections/users'
import { commonRouter } from './sections/common'
import { absenceRouter } from './sections/absences'
import { customerAdviserRouter } from './sections/customer-adviser'
import { statisticsRouter } from './sections/statistics'
import { globalPlanningRouter } from './sections/global-planning'
import { notificationsRouter } from './sections/notifications'
import { exportsRouter } from './sections/exports'

export const router = () => {
  const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/',
        name: 'home',
        children: [
          ...schedulingRouter,
          ...commonRouter,
          ...authRouter,
          ...customerAdviserRouter,
          ...documentationRouter,
          ...stockExchangeRouter,
          ...settingsRouter,
          ...usersRouter,
          ...exportsRouter,
          ...statisticsRouter,
          ...supportRouter,
          ...absenceRouter,
          ...projectsRouter,
          ...globalPlanningRouter,
          ...notificationsRouter,
        ],
      },
      {
        path: '/:pathMatch(.*)',
        name: 'error',
        component: () => import('@/sections/error/Error.vue'),
        props: { code: 404 },
      },
      {
        path: '/forbidden',
        name: 'forbidden',
        component: () => import('@/sections/error/Error.vue'),
        props: { code: 403 },
      },
    ],
  })

  router.beforeEach(async (...args) => await navigationManager(router, args))

  return router
}
