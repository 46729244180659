<script setup lang="ts">
  import { computed } from 'vue'

  import type { IconName } from '#/types'

  import { AlgIcon, AlgSpinner, IconBadgeState } from '#/components'

  import { ButtonSize, ButtonVariant } from '#/types'

  type Props = {
    readonly type?: 'button' | 'submit' | 'reset'
    readonly label?: string
    readonly variant?: ButtonVariant
    readonly size?: ButtonSize
    readonly disabled?: boolean
    readonly inactive?: boolean
    readonly block?: boolean
    readonly loading?: boolean
    readonly iconStart?: IconName
    readonly iconStartBadge?: boolean
    readonly iconStartBadgeState?: IconBadgeState
    readonly iconEnd?: IconName
    readonly iconEndBadge?: boolean
    readonly iconEndBadgeState?: IconBadgeState
  }

  defineOptions({
    name: 'AlgButton',
  })

  const props = withDefaults(defineProps<Props>(), {
    type: 'button',
    variant: 'primary',
    size: 'm',
    block: false,
    disabled: false,
    inactive: false,
    loading: false,
    iconBadge: false,
  })

  const emit = defineEmits<{
    (e: 'click', p: MouseEvent): void
    (e: 'focus', p: FocusEvent): void
    (e: 'blur', p: FocusEvent): void
  }>()

  const computedIconSize = computed(() => (props.size === 's' ? 'xs' : 's'))
</script>

<template>
  <button
    role="button"
    :type="props.type"
    class="button"
    :class="[
      `variant-${props.variant}`,
      `size-${props.size}`,
      {
        block: props.block,
        inactive: props.inactive,
        loading: props.loading,
      }
    ]"
    :aria-disabled="props.inactive || props.disabled || props.loading"
    :disabled="props.inactive || props.disabled || props.loading"
    tabindex="0"
    @click="(p) => emit('click', p)"
    @focus="(p) => emit('focus', p)"
    @blur="(p) => emit('blur', p)"
  >
    <AlgIcon
      v-if="props.iconStart && !props.loading"
      :name="props.iconStart"
      :size="computedIconSize"
      :badge="props.iconStartBadge"
      :badge-state="props.iconStartBadgeState"
    />
    <AlgSpinner
      v-else-if="props.loading"
      :size="computedIconSize"
    />
    <template v-if="props.label">
      {{ props.label }}
    </template>
    <slot v-else />
    <AlgIcon
      v-if="props.iconEnd"
      :name="props.iconEnd"
      :size="computedIconSize"
      :badge="props.iconEndBadge"
      :badge-state="props.iconEndBadgeState"
    />
  </button>
</template>

<style lang="scss" scoped>
.button {
  position: relative;
  display: inline-flex;
  overflow: hidden;
  width: auto;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid transparent;
  border-radius: var(--alg-effect-radius-m);
  margin: 0;
  appearance: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font: inherit;
  font-weight: var(--alg-font-weight-bold);
  text-overflow: ellipsis;
  transition-duration: var(--alg-transition-colors-duration);
  transition-property: var(--alg-transition-colors-property);
  transition-timing-function: var(--alg-transition-colors-timing-function);
  user-select: none;
  white-space: nowrap;

  &.block {
    display: flex;
    width: 100%;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.inactive,
  &.loading {
    cursor: default;
  }

  &.size-xs {
    min-width: 1.5rem;
    height: 1.5rem;
    border-radius: var(--alg-effect-radius-s);
    font-size: var(--alg-font-size-xs);
    gap: var(--alg-spacing-xxs);
    line-height: 1.5rem;
    padding-inline: calc(var(--alg-spacing-xs) - 0.063rem);
  }

  &.size-s {
    min-width: 1.875rem;
    height: 1.875rem;
    font-size: var(--alg-font-size-s);
    gap: var(--alg-spacing-xs);
    line-height: 1.875rem;
    padding-inline: calc(var(--alg-spacing-s) - 0.063rem);
  }

  &.size-m {
    min-width: 2.5rem;
    height: 2.5rem;
    font-size: var(--alg-font-size-m);
    gap: var(--alg-spacing-s);
    line-height: 2.5rem;
    padding-inline: calc(var(--alg-spacing-m) - 0.063rem);
  }

  &.variant-primary {
    border-color: var(--alg-color-button-primary);
    background-color: var(--alg-color-button-primary);
    color: var(--alg-color-text-on-color);

    &:focus-visible,
    &:hover {
      border-color: var(--alg-color-button-primary-hover);
      background-color: var(--alg-color-button-primary-hover);
    }

    &:disabled {
      border-color: var(--alg-color-button-primary-disabled);
      background-color: var(--alg-color-button-primary-disabled);
    }
  }

  &.variant-primary-light {
    border-color: var(--alg-color-button-primary-light);
    background-color: var(--alg-color-button-primary-light);
    color: var(--alg-color-text-highlight);

    &:focus-visible,
    &:hover {
      border-color: var(--alg-color-button-primary-light);
      background-color: var(--alg-color-button-primary-light-hover);
    }

    &:disabled {
      border-color: var(--alg-color-button-primary-light-disabled);
      background-color: var(--alg-color-button-primary-light-disabled);
      color: var(--alg-color-button-secondary-disabled);
    }
  }

  &.variant-secondary {
    border-color: var(--alg-color-surface-border);
    background-color: var(--alg-color-button-secondary);
    color: var(--alg-color-text-primary);

    &:focus-visible,
    &:hover {
      border-color: var(--alg-color-text-light);
    }

    &:disabled {
      color: var(--alg-color-button-secondary-disabled);

      &:hover {
        border-color: var(--alg-color-surface-border);
      }
    }
  }

  &.variant-tertiary {
    border-color: var(--alg-color-button-tertiary);
    background-color: var(--alg-color-button-tertiary);
    color: var(--alg-color-purple-100);

    &:focus-visible,
    &:hover {
      background-color: var(--alg-color-button-tertiary-hover);
    }

    &:disabled {
      background-color: var(--alg-color-button-tertiary-disabled);
      color: var(--alg-color-button-secondary-disabled);

      &:hover {
        border-color: var(--alg-color-button-tertiary);
      }
    }
  }

  &.variant-warning {
    border-color: var(--alg-color-button-warning);
    background-color: var(--alg-color-button-warning);
    color: var(--alg-color-text-on-color);

    &:focus-visible,
    &:hover {
      border-color: var(--alg-color-button-warning-hover);
      background-color: var(--alg-color-button-warning-hover);
    }

    &:disabled {
      border-color: var(--alg-color-button-warning-disabled);
      background-color: var(--alg-color-button-warning-disabled);
      color: var(--alg-color-button-secondary-disabled);

      &:hover {
        border-color: var(--alg-color-button-warning-disabled);
      }
    }
  }

  &.variant-link {
    height: auto;
    padding: 0 0 2px;
    border-color: var(--alg-color-transparent);
    border-radius: 0;
    background-color: var(--alg-color-transparent);
    color: var(--alg-color-button-primary);
    font-weight: var(--alg-font-weight-bold);
    line-height: inherit;
    text-underline-offset: 4px;

    &:hover {
      color: var(--alg-color-button-primary-hover);
      text-decoration: underline;
    }

    &:disabled {
      color: var(--alg-color-button-secondary-disabled);
      text-decoration: none;
    }
  }
}
</style>
