<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { useRouter } from 'vue-router'

  import { AlgIllustration } from '@algorh/ui'

  import Modal from '@/components/modals/Modal.vue'

  const emit = defineEmits<{
    (e: 'close'): void
  }>()

  const { t } = useI18n()

  const router = useRouter()

  async function handleConfirm() {
    await router.push({ name: 'login' })

    emit('close')
  }
</script>

<template>
  <Modal
    name="expired-session"
    size="m"
    :allow-close="false"
    :allow-cancel="false"
    :confirm-button-text="t('common.Continue')"
    @confirm="handleConfirm"
  >
    <template #title>
      {{ t('errors.Expired session') }}
    </template>
    <template #content>
      <div class="content-wrapper">
        <AlgIllustration
          name="forbidden"
          :size="148"
        />
        <p class="subtitle">
          {{ t('errors.Your session has expired!') }}
        </p>
        <p>{{ t('errors.Please log in again to continue using the application') }}</p>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss" src="./AlertModal.scss" scoped />
