import { DotNestedKeys, Nullable, round } from '@algorh/shared'

import { lang } from '@/plugins/langjs'

let callback: Nullable<(status: number) => void> = null

export const EMPTY_RESPONSE = {
  data: [],
  meta: null,
}

export const setCallback = (fn: typeof callback) => {
  callback = fn
}

export type FetchMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'

export interface FetchError<K extends string = string> {
  status: number
  message: string
  errors: Record<K, string[]>
}

export type ErrorObj<T> = Record<DotNestedKeys<T> | keyof T, string[]>

export interface FetchErrorObj<K> {
  status: number
  message: string
  errors?: ErrorObj<K>
}

export const getXSRFValueInCookie = (cookie: string) => {
  const match = cookie.match(new RegExp('(^| )XSRF-TOKEN=([^;]+)'))
  return match ? match[2].replace('%3D', '=') : null
}

export interface PaginationLink {
  active: boolean
  label: string
  url: Nullable<string>
}

export interface PaginationMeta {
  from: number
  to: number
  total: number
  per_page: number
  current_page: number
  last_page: number
  path: string
  links: PaginationLink[]
}

export interface FetchResult<T> {
  data: Nullable<T>
  meta?: Nullable<PaginationMeta>
}

export const fetchFile = async <A = undefined, B extends Params = Params> ({
  method,
  url,
  params,
  body,
  name,
  visualize = false,
  localCallback,
}: {
  method?: FetchMethod
  url: string
  body?: Nullable<A>
  params?: Nullable<B>
  name?: string
  visualize?: boolean
  localCallback?: () => void
}) => {
  let fullUrl = `${url}`
  if (params) {
    fullUrl += `?${getStringParams(params)}`
  }
  const request = new Request(fullUrl, {
    credentials: 'include',
    method: method ?? 'GET',
    body: body ? JSON.stringify(body) : null,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': getXSRFValueInCookie(document.cookie) ?? '',
    },
  })

  try {
    const response = await fetch(request)

    if (callback) {
      callback(response.status)
    }

    if (response.ok) {
      const header = response.headers.get('Content-Disposition')

      const filename = name ?? header?.match(/filename="(.+)"/)?.[1] ?? lang.trans('_.Untitled file')

      const a = document.createElement('a')

      const blob = await response.blob()

      const objectUrl = URL.createObjectURL(blob)

      a.setAttribute('target', '_blank')
      a.setAttribute('href', objectUrl)
      a.setAttribute(visualize ? 'name' : 'download', decodeURIComponent(filename))

      a.click()
      URL.revokeObjectURL(objectUrl)
      return { data: null, meta: null }
    } else {
      const error = await response.json()

      const allowedErrors = [404, 417, 422]

      if (allowedErrors.includes(response.status)) {
        throw {
          ...error,
          status: response.status,
        }
      } else {
        return { data: null, meta: null }
      }
    }
  } finally {
    if (localCallback) {
      localCallback()
    }
  }
}

export const fetchUpload = async <T>(
  method: FetchMethod,
  url: string,
  body?: FormData,
): Promise<FetchResult<T>> => {
  const request = new Request(url, {
    credentials: 'include',
    method,
    body,
    headers: {
      'Accept': 'application/json',
      'X-XSRF-TOKEN': getXSRFValueInCookie(document.cookie) ?? '',
    },
  })

  const response = await fetch(request)

  if (response.ok) {
    try {
      if (response.status === 204) {
        return { data: null, meta: null }
      }
      const parsed = await response.json()
      return parsed
    } catch (e) {
      throw new Error(`Failed parsing server response: ${e}`)
    }
  } else {
    if (response.status) {
      const err = await response.json()
      throw typeof err === 'object'
        ? {
            ...err,
            status: response.status,
          }
        : { err: err, status: response.status }
    } else {
      throw await response.json()
    }
  }
}

export type ParamsObjTypes = number[] | string[] | string | number | boolean | null | undefined
export type Params = Record<string, ParamsObjTypes | Record<string, ParamsObjTypes>[]>

export const getStringParams = (params: Params, parentKey?: string): string => Object.entries(params)
  .filter(([, value]) => {
    const isDefined = value != null
    const isEmptyArray = Array.isArray(value) && (value.length === 0 || value.every((i) => !i))
    return isDefined && !isEmptyArray
  })
  .map(([key, value]) => Array.isArray(value)
    ? value
      .map((v, i) => typeof v === 'object' && v !== null
        ? getStringParams(v, `${key}[${i}]`)
        : `${parentKey ?? key}[${i}]=${v}`)
      .join('&')
    : parentKey ? `${parentKey}[${key}]=${value}` : `${key}=${value}`)
  .join('&')

export const fetchJson = async <T = void, B = undefined, C extends Params = Params>(
  method: FetchMethod,
  url: string,
  body?: Nullable<B>,
  params?: Nullable<C>,
  signal?: AbortSignal,
  isResponseUndefined?: boolean,
): Promise<FetchResult<T>> => {
  let fullUrl = `${url}`
  if (params) {
    fullUrl += `?${getStringParams(params)}`
  }

  const request = new Request(fullUrl, {
    credentials: 'include',
    method,
    body: body ? JSON.stringify(body) : null,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': getXSRFValueInCookie(document.cookie) ?? '',
    },
  })

  const response = await fetch(request, { signal })

  if (callback) {
    callback(response.status)
  }

  if (response.ok) {
    try {
      if (response.status === 204 || isResponseUndefined) {
        return { data: null, meta: null }
      }

      const parsed = await response.json()
      return parsed as FetchResult<T>
    } catch (e) {
      throw new Error(`Failed parsing server response: ${e}`)
    }
  } else {
    const error = await response.json()

    const allowedErrors = [403, 404, 417, 422]

    if (allowedErrors.includes(response.status)) {
      throw {
        ...error,
        status: response.status,
      }
    } else {
      return { data: null, meta: null }
    }
  }
}

export function futch<T = unknown>(
  method: FetchMethod,
  url: string,
  body?: FormData,
  onProgress?: (loaded: number) => void,
): Promise<T> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open(method, url, true)

    xhr.setRequestHeader('Accept', 'application/json')
    xhr.setRequestHeader('X-XSRF-TOKEN', getXSRFValueInCookie(document.cookie) ?? '')

    xhr.onloadend = () => {
      if (![200, 201, 204, 206].includes(xhr.status)) {
        reject(JSON.parse(xhr.response))
        if (onProgress) onProgress(0)
      } else {
        resolve(xhr.response)
        // if (onProgress) onProgress()
      }
    }

    if (xhr.upload && onProgress)
      xhr.upload.onprogress = (event) => {
        if (xhr.status !== 204) {
          onProgress(round((event.loaded / event.total) * 100))
        }
      }

    xhr.send(body)
  })
}
