<script setup lang="ts">
  import { Nullable } from '@algorh/shared'

  import { lang } from '@/plugins/langjs'

  export interface MenuItem {
    content: string
    id: string | number
  }

  type Props = {
    readonly menuItems: MenuItem[]
    readonly highlighted: Nullable<string | number>
    readonly preventDuplicate?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    preventDuplicate: true,
  })

  const emit = defineEmits<{
    (e: 'onSelectItem', item: MenuItem): void
  }>()

  function onItemClick(item: MenuItem) {
    if (
      (props.highlighted !== item.id && props.preventDuplicate)
      || props.preventDuplicate === false
    ) {
      emit('onSelectItem', item)
    }
  }
</script>

<template>
  <nav
    class="sub-menu"
    :aria-label="lang.trans('_.Submenu')"
  >
    <ul class="sub-menu-container">
      <li
        v-for="(item, index) in props.menuItems"
        :key="index"
        class="item"
        :class="{
          active: highlighted === item.id
        }"
      >
        <button
          @click="onItemClick(item)"
          v-html="item.content"
        />
      </li>
    </ul>
    <span class="sub-menu-divider" />
  </nav>
</template>

<style lang="scss" scoped>
  .sub-menu {
    position: relative;
    display: flex;
    width: auto;

    &-container {
      display: flex;
      max-width: calc(100vw - 20px);
      flex-flow: row nowrap;
      gap: var(--alg-spacing-s);
      -webkit-overflow-scrolling: touch;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-border {
      position: relative;
      width: 100%;
      height: 3px;
      background-color: var(--color-primary-100);
    }
  }

  li {
    min-width: 20px;
    height: 32px;
  }

  .item {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;

    button {
      padding: 0;
      color: var(--color-grey-70);
      font-size: var(--size-normal);
      font-weight: bold;
      white-space: nowrap;
    }

    &.active {
      button {
        color: var(--color-grey-100);
      }

      &::after {
        position: absolute;
        top: 29px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: var(--color-primary-100);
        content: '';
      }
    }
  }
</style>
