<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { useRoute } from 'vue-router'

  import { useQuery } from '@tanstack/vue-query'

  import { AlgIcon } from '@algorh/ui'

  import { UserPermissions } from '@/api/interfaces/User'

  import { SchedulingSubdivisionsApiService } from '@/api/scheduling'

  import SvgIcon from '@/components/SvgIcon.vue'
  import UserInfos from '@/components/UserInfos.vue'

  import { useAppInit } from '@/composables/useAppInit'
  import { useConfigs } from '@/composables/useConfigs'

  import { lang } from '@/plugins/langjs'

  import { version } from '../../../../../package.json'

  import NavigationDisclosure from './NavigationDisclosure.vue'
  import { useIsMobile } from '@algorh/shared'

  const emit = defineEmits(['close', 'open-account-modal'])

  // Composables
  const { t } = useI18n()

  const route = useRoute()
  const isMobile = useIsMobile()

  const { me, permissions } = useAppInit()

  const {
    calendar,
    common: commonConfig,
    hyperPeriodEnabled,
    criticalPeriodEnabled,
    noticeEnabled,
    documentationEnabled,
    leaveBalancesImportEnabled,
    notificationsEnabled,
    schedulingEnabled,
    rgpdEnabled,
    absencesEnabled,
  } = useConfigs()

  // Data
  const matchedNames = route.matched.map((r) => r.name)
  const isSubdivisionOpen = matchedNames.includes('scheduling')
  const isParametersOpen = matchedNames.includes('settings')
  const isAbsencesOpen = matchedNames.includes('absences')

  // Methods
  function can(requestedPermissions: UserPermissions[]) {
    return requestedPermissions.some((permission) => permissions.value?.[permission])
  }

  function active(routes: string[]) {
    return routes.includes(route.name as string)
  }
  function closeMobileMenu() {
    if (isMobile.value) {
      emit('close')
    }
  }

  const { data: subdivisions } = useQuery({
    enabled: schedulingEnabled,
    queryKey: ['subdivision'],
    queryFn: SchedulingSubdivisionsApiService.getSubdivisions,
  })

</script>

<template>
  <div class="navigation-menu">
    <UserInfos
      :user="me?.data"
      @click="() => emit('open-account-modal')"
    />

    <!-- Planning -->
    <div class="nav-links">
      <RouterLink
        v-if="can([UserPermissions.SEE_GLOBAL_PLANNING])"
        class="nav-link"
        :class="{
          active: active(['global-planning'])
        }"
        to="/global-planning"
        @click="closeMobileMenu"
      >
        <SvgIcon
          class="nav-link-icon"
          name="material/calendar-today"
          :size="20"
        />
        <span>
          {{ lang.trans('_.Global planning') }}
        </span>
      </RouterLink>

      <RouterLink
        v-if="can([UserPermissions.ACCESS_CALENDAR])"
        class="nav-link"
        :class="{
          active: active(['calendar'])
        }"
        :to="{ name: 'calendar' }"
        @click="closeMobileMenu"
      >
        <SvgIcon
          class="nav-link-icon"
          name="material/calendar-today"
          :size="20"
        />
        <span>
          {{ t('calendar.My planning') }}
        </span>
      </RouterLink>

      <!-- Notifications -->
      <RouterLink
        v-if="permissions?.access_calendar && notificationsEnabled"
        class="nav-link"
        :to="{ name: 'notifications' }"
        :class="{
          active: active(['notifications'])
        }"
        @click="closeMobileMenu"
      >
        <SvgIcon
          class="nav-link-icon"
          name="material/notifications"
          :size="20"
        />
        <span>
          {{ t('common.Notifications') }}
        </span>
      </RouterLink>

      <!-- Projects -->
      <RouterLink
        v-if="can([UserPermissions.MANAGE_PROJECTS])"
        class="nav-link"
        :to="{ name: 'projects' }"
        :class="{
          active: active(['projects', 'project-list','project-activities', 'project-rules', 'project-ambitions'])
        }"
        @click="closeMobileMenu"
      >
        <SvgIcon
          class="nav-link-icon"
          name="material/design-services"
          :size="20"
        />
        <span>
          {{ lang.trans('_.Projects') }}
        </span>
      </RouterLink>

      <!-- Planification -->
      <template v-if="schedulingEnabled">
        <template v-if="can([UserPermissions.ACCESS_SCHEDULING])">
          <NavigationDisclosure :open="isSubdivisionOpen">
            <template #summary>
              <div class="nav-link">
                <SvgIcon
                  class="nav-link-icon"
                  name="material/tune"
                  :size="20"
                />
                <span>
                  {{ lang.trans('_.Scheduling') }}
                </span>
              </div>
            </template>
            <template #details>
              <template v-if="subdivisions?.data?.length">
                <RouterLink
                  v-for="subdivision in subdivisions.data"
                  :key="subdivision.id"
                  class="nav-link sub-link"
                  :to="`/scheduling/subdivision/${subdivision.id}/dashboard`"
                  :class="{
                    active: subdivision.id.toString() === route.params.subdivisionId
                  }"
                  @click="closeMobileMenu"
                >
                  <span class="link-line">
                    <i
                      class="dot"
                      :style="{ backgroundColor: subdivision.project.color }"
                    />
                    {{ subdivision.name }}
                  </span>
                </RouterLink>
              </template>
              <div
                v-else
                class="no-data"
              >
                <AlgIcon
                  name="warning"
                  size="s"
                />
                {{ t('subdivisions.No subdivision') }}
              </div>
            </template>
          </NavigationDisclosure>
        </template>

        <!-- Statistics -->
        <RouterLink
          v-if="can([UserPermissions.ACCESS_PROJECT_STATISTICS, UserPermissions.ACCESS_STATISTICS])"
          to="/statistics"
          class="nav-link"
          :class="{
            active: active(['statistics'])
          }"
          @click="closeMobileMenu"
        >
          <SvgIcon
            class="nav-link-icon"
            name="material/data-usage"
            :size="20"
          />
          <span>
            {{ lang.trans('_.Statistics') }}
          </span>
        </RouterLink>
      </template>

      <!-- Absences -->
      <template v-if="absencesEnabled">
        <NavigationDisclosure :open="isAbsencesOpen">
          <template #summary>
            <div class="nav-link">
              <SvgIcon
                class="nav-link-icon"
                name="material/person-off"
                :size="20"
              />
              <span>
                {{ lang.trans('_.Absences') }}
              </span>
            </div>
          </template>
          <template #details>
            <RouterLink
              v-if="can([UserPermissions.SEE_ABSENCES])"
              class="nav-link sub-link"
              :to="{ name: 'absences-my-teams-absences' }"
              :class="{
                active: active(['absences-my-teams-absences'])
              }"
              @click="closeMobileMenu"
            >
              <span>
                {{ t('common.Dashboard') }}
              </span>
            </RouterLink>

            <RouterLink
              class="nav-link sub-link"
              :to="{ name: 'absences-leave-request' }"
              :class="{ active: active(['absences-leave-request']) }"
              @click="closeMobileMenu"
            >
              <span>
                {{ lang.trans('_.Request an absence') }}
              </span>
            </RouterLink>

            <RouterLink
              class="nav-link sub-link"
              :to="{ name: 'absences-my-absences' }"
              :class="{
                active: active(['absences-my-absences', 'absences-my-absence-details'])
              }"
              @click="closeMobileMenu"
            >
              <span>
                {{ lang.trans('_.My absences') }}
              </span>
            </RouterLink>

            <RouterLink
              v-if="can([
                UserPermissions.ACCEPT_OR_DENY_PENDING_ABSENCES
              ])
              "
              class="nav-link sub-link"
              :to="{ name: 'absences-validations' }"
              :class="{
                active: active(['absences-validations', 'absences-validations-list', 'absences-validation-details'])
              }"
              @click="closeMobileMenu"
            >
              <span>
                {{ lang.trans('_.Absence validation') }}
              </span>
            </RouterLink>

            <RouterLink
              v-if="criticalPeriodEnabled &&
                can([
                  UserPermissions.ACCESS_ABSENCES_ON_CRITICAL_PERIODS,
                ])
              "
              class="nav-link sub-link"
              :to="{ name: 'absences-critical-period-absences' }"
              :class="{
                active: active(['absences-critical-period-absences'])
              }"
              @click="closeMobileMenu"
            >
              <span>
                {{ lang.trans('_.Critical period absences') }}
              </span>
            </RouterLink>

            <RouterLink
              v-if="can([UserPermissions.CREATE_ABSENCES_FOR_OTHER])"
              class="nav-link sub-link"
              :to="{ name: 'absences-employees' }"
              :class="{
                active: active(['absences-employees','absences-employees-list', 'absences-employee-details'])
              }"
              @click="closeMobileMenu"
            >
              <span>
                {{ lang.trans('_.Absence of an employee') }}
              </span>
            </RouterLink>

            <RouterLink
              v-if="can([UserPermissions.ACCESS_VALIDATION_PROCESSES])"
              class="nav-link sub-link"
              :to="{ name: 'absences-validation-processes' }"
              :class="{
                active: active([
                  'absences-validation-processes',
                  'absences-validation-process-list',
                  'absences-validation-process',
                  'absences-validation-process-create'
                ])
              }"
              @click="closeMobileMenu"
            >
              <span>
                {{ lang.trans('_.Validation management') }}
              </span>
            </RouterLink>

            <template v-if="leaveBalancesImportEnabled">
              <RouterLink
                v-if="can([UserPermissions.IMPORT_LEAVE_BALANCES])"
                class="nav-link sub-link"
                :to="{ name: 'absences-import-leave-balances' }"
                :class="{
                  active: active(['absences-import-leave-balances'])
                }"
                @click="closeMobileMenu"
              >
                <span>
                  {{ t('absences.Import leave balances') }}
                </span>
              </RouterLink>
            </template>
          </template>
        </NavigationDisclosure>
      </template>

      <!-- Stock exchange -->
      <RouterLink
        v-if="calendar?.data?.stock_exchanges"
        to="/stock-exchange"
        class="nav-link"
        :class="{
          active: active([
            'stock-exchange',
            'stock-exchange-day-off-list',
            'stock-exchange-day-off-requests-and-offers',
            'stock-exchange-slot-list',
            'stock-exchange-slot-requests-and-offers'
          ])
        }"
        @click="closeMobileMenu"
      >
        <AlgIcon
          class="nav-link-icon"
          name="storefront"
          size="m"
        />
        <span>
          {{ t('stockExchange.Stock exchange') }}
        </span>
      </RouterLink>

      <!-- Settings -->
      <template
        v-if="can([
          UserPermissions.ACCESS_HYPER_PERIODS,
          UserPermissions.MANAGE_ACTIVITIES,
          UserPermissions.MANAGE_RULES_AND_AMBITIONS,
          UserPermissions.MANAGE_USERS,
          UserPermissions.MANAGE_ROLES,
          UserPermissions.ACCESS_WORK_CYCLES,
          UserPermissions.MANAGE_WORK_CYCLES,
          UserPermissions.ACCESS_TAGS
        ])
        "
      >
        <NavigationDisclosure :open="isParametersOpen">
          <template #summary>
            <div class="nav-link">
              <SvgIcon
                class="nav-link-icon"
                name="material/settings"
                :size="20"
              />
              <span>
                {{ lang.trans('_.Parameters') }}
              </span>
            </div>
          </template>
          <template #details>
            <RouterLink
              v-if="hyperPeriodEnabled && can([UserPermissions.ACCESS_HYPER_PERIODS])"
              class="nav-link sub-link"
              to="/settings/hyper-periods"
              :class="{
                active: active(['settings-hyper-periods', 'settings-hyper-period'])
              }"
              @click="closeMobileMenu"
            >
              <span>
                {{ lang.trans('_.Hyper periods') }}
              </span>
            </RouterLink>
            <template v-if="schedulingEnabled">
              <RouterLink
                v-if="can([UserPermissions.MANAGE_ACTIVITIES])"
                class="nav-link sub-link"
                to="/settings/activities"
                :class="{
                  active: active(['settings-activities'])
                }"
                @click="closeMobileMenu"
              >
                <span>
                  {{ lang.trans('_.Activities') }}
                </span>
              </RouterLink>
              <RouterLink
                v-if="can([UserPermissions.MANAGE_RULES_AND_AMBITIONS])"
                class="nav-link sub-link"
                to="/settings/rules-ambitions/rules"
                :class="{
                  active: active(['settings-rules', 'settings-ambitions'])
                }"
              >
                <span>
                  {{ lang.trans('_.Rules and Ambitions') }}
                </span>
              </RouterLink>
            </template>
            <RouterLink
              v-if="can([UserPermissions.ACCESS_TAGS])"
              class="nav-link sub-link"
              to="/settings/tags"
              :class="{
                active: active(['settings-tags'])
              }"
              @click="closeMobileMenu"
            >
              <span>
                {{ t('tags.Tags') }}
              </span>
            </RouterLink>
            <RouterLink
              v-if="can([UserPermissions.MANAGE_USERS])"
              class="nav-link sub-link"
              to="/settings/users"
              :class="{
                active: active([
                  'settings-users',
                  'settings-users-list',
                  'settings-users-create',
                  'settings-users-update'
                ])
              }"
              @click="closeMobileMenu"
            >
              <span>
                {{ lang.trans('_.User accounts') }}
              </span>
            </RouterLink>
            <template v-if="schedulingEnabled && commonConfig?.data?.work_cycle">
              <RouterLink
                v-if="can([UserPermissions.ACCESS_WORK_CYCLES])"
                class="nav-link sub-link"
                to="/settings/work-cycles"
                :class="{
                  active: active([
                    'settings-work-cycles',
                    'settings-work-cycles-list',
                    'settings-work-cycles-create',
                    'settings-work-cycles-update',
                    'settings-work-cycles-show'
                  ])
                }"
                @click="closeMobileMenu"
              >
                <span>
                  {{ lang.trans('_.Work cycles') }}
                </span>
              </RouterLink>
            </template>
            <RouterLink
              v-if="can([UserPermissions.MANAGE_SUBDIVISIONS])"
              class="nav-link sub-link"
              to="/settings/subdivisions"
              :class="{
                active: active(['settings-subdivisions'])
              }"
              @click="closeMobileMenu"
            >
              <span>
                {{ lang.trans('_.Subdivisions') }}
              </span>
            </RouterLink>
            <RouterLink
              v-if="can([UserPermissions.MANAGE_ROLES])"
              class="nav-link sub-link"
              to="/settings/roles-permissions"
              :class="{
                active: active(['settings-roles-permissions'])
              }"
              @click="closeMobileMenu"
            >
              <span>
                {{ lang.trans('_.Roles and permissions') }}
              </span>
            </RouterLink>
          </template>
        </NavigationDisclosure>
      </template>

      <template v-if="schedulingEnabled">
        <!-- Users -->
        <RouterLink
          v-if="can([
            UserPermissions.MANAGE_CUSTOMER_ADVISERS,
            UserPermissions.ACCESS_CUSTOMER_ADVISERS
          ])
          "
          class="nav-link"
          to="/users/customer-advisers"
          :class="{
            active: active([
              'users-customer-advisers',
              'users-customer-adviser',
              'users-supervisors',
              'users-customer-adviser-edit',
              'users-customer-advisers-list',
              'users-customer-adviser-details',
              'users-customer-adviser-others'
            ])
          }"
          @click="closeMobileMenu"
        >
          <SvgIcon
            class="nav-link-icon"
            name="material/group"
            :size="20"
          />
          <span>
            {{ lang.trans('_.Users') }}
          </span>
        </RouterLink>
      </template>

      <template v-if="schedulingEnabled">
        <!-- Exports -->
        <RouterLink
          v-if="can([UserPermissions.ACCESS_EXPORTS])"
          class="nav-link"
          to="/exports"
          :class="{
            active: active([
              'exports',
            ])
          }"
          @click="closeMobileMenu"
        >
          <SvgIcon
            class="nav-link-icon"
            name="material/bar-chart-4-bars"
            :size="20"
          />
          <span>
            {{ lang.trans('_.Data export') }}
          </span>
        </RouterLink>
      </template>

      <div
        v-if="[rgpdEnabled, documentationEnabled, noticeEnabled].includes(true)"
        class="separator"
      />
      <!-- Documentation -->
      <RouterLink
        v-if="noticeEnabled"
        :to="{ name: 'documentation-information-notice' }"
        :class="{ active: active(['documentation-information-notice']) }"
        class="nav-link"
        @click="closeMobileMenu"
      >
        <SvgIcon
          class="nav-link-icon"
          name="material/info"
          :size="20"
        />
        <span>
          {{ lang.trans('_.Information notice') }}
        </span>
      </RouterLink>
      <RouterLink
        v-if="documentationEnabled"
        :to="{ name: 'documentation-online-support' }"
        :class="{ active: active(['documentation-online-support']) }"
        class="nav-link"
        @click="closeMobileMenu"
      >
        <SvgIcon
          class="nav-link-icon"
          name="material/support"
          :size="20"
        />
        <span>
          {{ lang.trans('_.Online support') }}
        </span>
      </RouterLink>
      <RouterLink
        v-if="rgpdEnabled"
        to="/documentation/gdpr"
        :class="{ active: active(['documentation-gdpr']) }"
        class="nav-link"
        @click="closeMobileMenu"
      >
        <SvgIcon
          class="nav-link-icon"
          name="material/info"
          :size="18"
        />
        <span>
          {{ lang.trans('_.GDPR') }}
        </span>
      </RouterLink>
    </div>
    <div class="navigation-menu-footer">
      {{
        lang.trans('_.© SoyHuCe :date - Version :version', {
          date: new Date().getFullYear().toString(),
          version: version
        })
      }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@property --gradient-value{
  inherits: false;
  initial-value: 0%;
  syntax: '<percentage>';
}

.navigation-menu {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding-top: 64px;
  background-color: var(--color-light);
  box-shadow: var(--box-shadow);

  .navigation-menu-footer {
    padding: 16px 20px;
    margin-top: auto;
    color: var(--color-grey-60);
    font-size: var(--size-xs);
    font-weight: 400;
    text-align: center;
  }

  &:deep(.user-infos) {
    width: 100%;
    box-sizing: border-box;
    padding: 12px 24px 12px 20px;
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
    cursor: pointer;

    .details {
      overflow: hidden;
      width: 100%;
    }

    .category {
      overflow: hidden;
      width: 100%;
      height: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .nav-links {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow-y: auto;

    .nav-link {
      display: flex;
      box-sizing: border-box;
      align-items: center;
      padding: 8px;
      border-radius: var(--radius-normal);
      margin: 4px 12px;
      color: var(--color-grey-100);
      font-size: var(--size-s);
      font-weight: 700;
      user-select: none;

      &:hover {
        color: var(--color-primary-100);
      }

      &.active {
        background-color: var(--color-background);
      }

      span {
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .link-line {
        display: flex;
        align-items: center;
        gap: var(--alg-spacing-s);
      }

      .nav-link-icon {
        padding-left: 4px;
        margin-right: 10px;
        color: var(--color-grey-70);
      }

      &.sub-link {
        position: relative;
        box-sizing: border-box;
        padding: 0 4px 0 16px;
        color: var(--color-grey-70);
        font-weight: 600;

        span {
          padding: 10px;
          border-radius: var(--radius-normal);
          margin-left: 12px;
        }

        &::before {
          position: absolute;
          top: 4px;
          bottom: 4px;
          width: 2px;
          border-radius: 2px;
          background: linear-gradient(var(--color-primary-100) var(--gradient-value), var(--color-background) 0%);
          content: '';
          transition: --gradient-value 0.25s;
        }

        &:hover,
        &.active {
          background-color: var(--color-transparent);
          color: var(--color-primary-100);

          .nav-link-icon {
            color: var(--color-primary-100);
          }
        }

        &.active {
          &::before {
            --gradient-value: 100%;
          }

          span {
            background-color: var(--color-background);
            font-weight: bold;
          }
        }
      }

      &:hover,
      &.active {
        color: var(--color-primary-100);

        .nav-link-icon {
          color: var(--color-primary-100);
        }
      }
    }
  }

  .separator {
    width: 64px;
    height: 1px;
    min-height: 1px; // Fix overflow issue
    border-radius: 1px;
    margin: 12px 58px;
    background-color: var(--color-border);
  }

  @media screen and (width >=1920px) {
    .nav-links {
      .nav-link {
        padding: 12px 8px;
        font-size: var(--size-normal);

        .sub-link {
          padding: 0 8px 0 16px;
        }
      }
    }
  }
}

.no-data {
  display: flex;
  align-items: center;
  padding-left: var(--alg-spacing-l);
  margin: var(--alg-spacing-xs) 0 var(--alg-spacing-xs) var(--alg-spacing-l);
  color: var(--alg-color-text-secondary);
  font-size: var(--alg-font-size-s);
  font-weight: 600;
  gap: var(--alg-spacing-s);
}

.dot {
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
  border-radius: 8px;
}
</style>
