import { RouteRecordRaw, RouterView } from 'vue-router'

import { UserPermissions } from '@/api/interfaces/User'
import AppLayout from '@/layouts/app/App.vue'

const settingsRouter: RouteRecordRaw[] = [
  {
    path: 'settings',
    name: 'settings',
    meta: {
      breadcrumb: 'Settings',
      breadcrumbInactive: true,
    },
    children: [
      // Hyper periods
      {
        path: 'hyper-periods',
        name: 'settings-hyper-periods',
        component: () => import('@/sections/settings/hyper-periods/HyperPeriods.vue'),
        meta: {
          layout: AppLayout,
          permissions: [UserPermissions.ACCESS_HYPER_PERIODS],
          breadcrumb: 'Hyper periods',
        },
        children: [
          {
            path: ':id/:stepId?',
            name: 'settings-hyper-period',
            component: () =>
              import('@/sections/settings/hyper-periods/components/HyperPeriodsStepper.vue'),
          },
        ],
      },
      // Activities
      {
        path: 'activities',
        name: 'settings-activities',
        component: () => import('@/sections/settings/activities/Activities.vue'),
        meta: {
          layout: AppLayout,
          permissions: [UserPermissions.MANAGE_ACTIVITIES],
          breadcrumb: 'Activities',
        },
      },
      // Rules and ambitions
      {
        path: 'rules-ambitions',
        name: 'settings-rules-ambitions',
        redirect: 'settings-rules-ambitions',
        component: () => import('@/sections/settings/rules-ambitions/RulesAmbitions.vue'),
        meta: {
          layout: AppLayout,
          permissions: [UserPermissions.MANAGE_RULES_AND_AMBITIONS],
          breadcrumb: 'Rules and Ambitions',
          breadcrumbInactive: true,
        },
        children: [
          {
            path: 'rules',
            name: 'settings-rules',
            component: () => import('@/sections/settings/rules-ambitions/GlobalRules.vue'),
            meta: {
              breadcrumb: 'Rules',
            },
          },
          {
            path: 'ambitions',
            name: 'settings-ambitions',
            component: () => import('@/sections/settings/rules-ambitions/GlobalAmbitions.vue'),
            meta: {
              breadcrumb: 'Ambitions',
            },
          },
        ],
      },
      // Tags
      {
        path: 'tags',
        name: 'settings-tags',
        component: () => import('@/sections/settings/tags/Tags.vue'),
        meta: {
          layout: AppLayout,
          permissions: [UserPermissions.ACCESS_TAGS],
          breadcrumb: 'Tags',
        },
      },
      // Users
      {
        path: 'users',
        name: 'settings-users',
        redirect: { name: 'settings-users-list' },
        component: RouterView,
        meta: {
          layout: AppLayout,
          permissions: [UserPermissions.MANAGE_USERS],
          breadcrumb: 'User accounts',
        },
        children: [
          {
            path: '',
            name: 'settings-users-list',
            component: () => import('@/sections/settings/users/Users.vue'),
          },
          {
            path: 'create',
            name: 'settings-users-create',
            component: () => import('@/sections/settings/users/CreateUser.vue'),
          },
          {
            path: ':userId',
            name: 'settings-user',
            component: RouterView,
            redirect: { name: 'settings-users-update' },
            meta: {
              breadcrumb: ':userId',
            },
            children: [
              {
                path: '',
                name: 'settings-users-update',
                component: () => import('@/sections/settings/users/EditUser.vue'),
              },
              {
                path: 'work-cycles',
                name: 'settings-users-work-cycles',
                component: RouterView,
                meta: {
                  breadcrumb: 'Work cycles',
                  breadcrumbInactive: true,
                },
                children: [
                  {
                    path: 'create',
                    name: 'settings-users-work-cycles-create',
                    component: () =>
                      import('@/sections/settings/users/personal-work-cycles/PersonalWorkCycleForm.vue'),
                    meta: {
                      breadcrumb: 'Add work cycle',
                    },
                  },
                  {
                    path: ':workCycleId',
                    name: 'settings-users-work-cycles-update',
                    component: () =>
                      import('@/sections/settings/users/personal-work-cycles/PersonalWorkCycleForm.vue'),
                    meta: {
                      breadcrumb: ':workCycleId',
                    },
                  },
                  { // @TODO a supprimer car gérer dans les droits pour la lecture seule
                    path: ':workCycleId/show',
                    name: 'settings-users-work-cycles-show',
                    component: () =>
                      import('@/sections/settings/users/personal-work-cycles/PersonalWorkCycleForm.vue'),
                    meta: {
                      breadcrumb: ':workCycleId',
                    },
                  },
                ],
              },
            ],
          },

        ],
      },
      // Work cycles
      {
        path: 'work-cycles',
        name: 'settings-work-cycles',
        redirect: { name: 'settings-work-cycles-list' },
        component: RouterView,
        meta: {
          layout: AppLayout,
          permissions: [UserPermissions.ACCESS_WORK_CYCLES],
          breadcrumb: 'Work cycles',
        },
        children: [
          {
            path: '',
            name: 'settings-work-cycles-list',
            component: () => import('@/sections/settings/work-cycles/WorkCycles.vue'),
          },
          {
            path: 'create',
            name: 'settings-work-cycles-create',
            component: () => import('@/sections/settings/work-cycles/WorkCycleForm.vue'),
          },
          {
            path: 'update/:id',
            name: 'settings-work-cycles-update',
            component: () => import('@/sections/settings/work-cycles/WorkCycleForm.vue'),
          },
          {
            path: 'show/:id',
            name: 'settings-work-cycles-show',
            component: () => import('@/sections/settings/work-cycles/WorkCycleForm.vue'),
          },
        ],
      },
      // Subdivisions
      {
        path: 'subdivisions',
        name: 'settings-subdivisions',
        component: () => import('@/sections/settings/subdivisions/Subdivisions.vue'),
        meta: {
          layout: AppLayout,
          permissions: [UserPermissions.MANAGE_SUBDIVISIONS],
          breadcrumb: 'Subdivisions',
        },
      },
      // Roles and permissions
      {
        path: 'roles-permissions',
        name: 'settings-roles-permissions',
        component: () => import('@/sections/settings/roles-permissions/RolesPermissions.vue'),
        meta: {
          layout: AppLayout,
          permissions: [UserPermissions.MANAGE_ROLES],
          breadcrumb: 'Roles and permissions',
        },
      },
    ],
  },
]

export { settingsRouter }
