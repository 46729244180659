import { RouteRecordRaw } from 'vue-router'
import AppLayout from '@/layouts/app/App.vue'

const commonRouter: RouteRecordRaw[] = [
  {
    path: 'landing',
    name: 'landing',
    meta: {
      redirectable: true,
      weight: 0,
      layout: AppLayout,
    },
    component: () => import('@/sections/common/Landing.vue'),
  },
]

export { commonRouter }
