<script lang="ts" setup>
  import { storeToRefs } from 'pinia'

  import { AlgIllustration } from '@algorh/ui'

  import { useAppStore } from '@/appStore'

  import SvgIcon from '@/components/SvgIcon.vue'

  interface Props {
    readonly size?: number
    readonly withLogo?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    size: 128,
    withLogo: true,
  })

  const appStore = useAppStore()

  const { appLogo } = storeToRefs(appStore)
</script>

<template>
  <div class="loader">
    <span
      v-if="props.withLogo && props.size >= 64"
      class="logo"
    >
      <AlgIllustration
        prefix="brand"
        :name="appLogo"
        :size="props.size * 0.6"
      />
    </span>
    <span class="spinner">
      <SvgIcon
        name="loaders/spinner"
        type="stroke"
        :size="props.size"
      />
    </span>
  </div>
</template>

<style lang="scss" scoped>
  .loader {
    position: relative;
    width: fit-content;
    height: fit-content;

    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .spinner {
      color: var(--alg-color-surface-highlight);
      opacity: 0.5;
    }
  }
</style>
