<script lang="ts" setup>
  import { useRouter } from 'vue-router'

  import { useI18n } from 'vue-i18n'

  import { storeToRefs } from 'pinia'

  import { useNotificationStore } from '@/sections/notifications/useNotificationStore'
  import { useIsMobile } from '@/composables'

  import {
    AlgIconButton,
    AlgNotificationsPanel,
    AlgList,
    AlgListHeader,
    AlgListItem,
    AlgIcon,
    AlgBadge,
    AlgPopper,
  } from '@algorh/ui'

  const router = useRouter()
  const { t } = useI18n()

  const notificationStore = useNotificationStore()

  const { markAsReadNotifications, markAsReadActivityChangeNotifications } = notificationStore

  const {
    activityChangeNotifications,
    unreadNotifications,
    unreadPlanningUpdateNotifications,
    unreadExchangeNotifications,
  } = storeToRefs(notificationStore)

  const isMobile = useIsMobile()

  function handleNotificationsValidation(uuids: string[]) {
    markAsReadNotifications(uuids)
  }

  function handleGoToNotificationCenter(callback?: () => void) {
    if (callback) callback()
    router.push({ name: 'notifications' })
  }
</script>

<template>
  <div class="page-header-notifications">
    <AlgPopper placement="bottom-right">
      <template #reference="{toggle, isOpen}">
        <div class="page-header-notifications-icon">
          <AlgIconButton
            id="toggle-notifications"
            icon="notifications"
            :icon-color="isOpen ? 'var(--color-primary-100)' : 'var(--alg-color-icon-secondary)'"
            size="l"
            variant="transparent"
            @click="toggle"
          />
          <span
            v-show="unreadNotifications.length > 0"
            class="page-header-notifications-icon-dot"
          >
            <AlgBadge
              size="xs"
              dot
              variant="danger"
            />
          </span>
        </div>
      </template>
      <template #content="{close}">
        <AlgNotificationsPanel
          :fullscreen="isMobile"
          @go-to-notification-center="() => handleGoToNotificationCenter(close)"
          @close="close"
        >
          <AlgList>
            <AlgListHeader>
              <template #title>
                {{ t('notifications.Real time') }}
              </template>
              <template #append>
                <AlgIconButton
                  id="mark-all-as-read-real-time"
                  icon="done-all"
                  :icon-color="activityChangeNotifications.length === 0 ?
                    'var(--alg-color-icon-unselected)' : 'var(--alg-color-icon-secondary)'"
                  variant="transparent"
                  size="m"
                  tooltip
                  tooltip-placement="left"
                  :label="t('notifications.Mark all as read')"
                  :disabled="activityChangeNotifications.length === 0"
                  @click="
                    () =>
                      markAsReadActivityChangeNotifications(
                        activityChangeNotifications.map(({ id }) => id)
                      )
                  "
                />
              </template>
            </AlgListHeader>
            <template v-if="activityChangeNotifications.length">
              <AlgListItem
                v-for="notification in activityChangeNotifications"
                :key="notification.id"
              >
                <template #prepend>
                  <AlgIcon
                    name="schedule"
                    size="m"
                    color="var(--alg-color-pink-100)"
                  />
                </template>
                <template #title>
                  {{ notification.content.subject }}
                </template>
                <template #description>
                  {{ notification.content.message }}
                </template>
                <template #append>
                  <AlgIconButton
                    :id="`mark-as-read-real-time-${notification.id}`"
                    icon="check"
                    icon-color="var(--alg-color-icon-secondary)"
                    variant="transparent"
                    size="m"
                    tooltip
                    tooltip-placement="left"
                    :label="t('notifications.Mark as read')"
                    @click="() => markAsReadActivityChangeNotifications([notification.id])"
                  />
                </template>
              </AlgListItem>
            </template>
            <template v-else>
              <AlgListItem>
                <template #description>
                  {{ t('account.No new notification') }}
                </template>
              </AlgListItem>
            </template>
            <AlgListHeader>
              <template #title>
                {{ t('notifications.Planning update') }}
              </template>
              <template #append>
                <AlgIconButton
                  id="mark-all-as-read-planning-update"
                  icon="done-all"
                  :icon-color="unreadPlanningUpdateNotifications.length === 0 ?
                    'var(--alg-color-icon-unselected)' : 'var(--alg-color-icon-secondary)'"
                  variant="transparent"
                  size="m"
                  tooltip
                  tooltip-placement="left"
                  :label="t('notifications.Mark all as read')"
                  :disabled="unreadPlanningUpdateNotifications.length === 0"
                  @click="
                    () =>
                      handleNotificationsValidation(
                        unreadPlanningUpdateNotifications.map(({ id }) => id)
                      )
                  "
                />
              </template>
            </AlgListHeader>
            <template v-if="unreadPlanningUpdateNotifications.length">
              <AlgListItem
                v-for="notification in unreadPlanningUpdateNotifications"
                :key="notification.id"
              >
                <template #prepend>
                  <AlgIcon
                    name="schedule"
                    size="m"
                    color="var(--alg-color-pink-100)"
                  />
                </template>
                <template #title>
                  {{ notification.content.subject }}
                </template>
                <template #description>
                  {{ notification.content.message }}
                </template>
                <template #append>
                  <AlgIconButton
                    :id="`mark-as-read-planning-update-${notification.id}`"
                    icon="check"
                    icon-color="var(--alg-color-icon-secondary)"
                    variant="transparent"
                    size="m"
                    tooltip
                    tooltip-placement="left"
                    :label="t('notifications.Mark as read')"
                    @click="() => handleNotificationsValidation([notification.id])"
                  />
                </template>
              </AlgListItem>
            </template>
            <template v-else>
              <AlgListItem>
                <template #description>
                  {{ t('account.No new notification') }}
                </template>
              </AlgListItem>
            </template>
            <AlgListHeader>
              <template #title>
                {{ t('notifications.Exchange') }}
              </template>
              <template #append>
                <AlgIconButton
                  id="mark-all-as-read-exchange"
                  icon="done-all"
                  :icon-color="unreadExchangeNotifications.length === 0 ?
                    'var(--alg-color-icon-unselected)' : 'var(--alg-color-icon-secondary)'"
                  variant="transparent"
                  size="m"
                  tooltip
                  tooltip-placement="left"
                  :label="t('notifications.Mark all as read')"
                  :disabled="unreadExchangeNotifications.length === 0"
                  @click="
                    () =>
                      handleNotificationsValidation(
                        unreadExchangeNotifications.map(({ id }) => id)
                      )
                  "
                />
              </template>
            </AlgListHeader>
            <template v-if="unreadExchangeNotifications.length">
              <AlgListItem
                v-for="notification in unreadExchangeNotifications"
                :key="notification.id"
              >
                <template #prepend>
                  <AlgIcon
                    name="business-center"
                    size="m"
                    color="var(--alg-color-cyan-100)"
                  />
                </template>
                <template #title>
                  {{ notification.content.subject }}
                </template>
                <template #description>
                  {{ notification.content.message }}
                </template>
                <template #append>
                  <AlgIconButton
                    :id="`mark-as-read-exchange-${notification.id}`"
                    icon="check"
                    icon-color="var(--alg-color-icon-secondary)"
                    variant="transparent"
                    size="m"
                    tooltip
                    tooltip-placement="left"
                    :label="t('notifications.Mark as read')"
                    @click="() => handleNotificationsValidation([notification.id])"
                  />
                </template>
              </AlgListItem>
            </template>
            <template v-else>
              <AlgListItem>
                <template #description>
                  {{ t('account.No new notification') }}
                </template>
              </AlgListItem>
            </template>
          </AlgList>
        </AlgNotificationsPanel>
      </template>
    </AlgPopper>
  </div>
</template>

<style lang="scss" scoped>
  .page-header-notifications {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .page-header-notifications-icon {
      position: relative;
    }

    .page-header-notifications-icon-dot {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(25%, -25%);
    }
  }
</style>
