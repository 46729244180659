<script setup lang="ts">
  import { computed, useCssModule } from 'vue'

  import { AlgIcon, BadgeVariant, BadgeSize } from '#/components'

  import type { IconName } from '#/types'

  defineOptions({
    name: 'AlgBadge',
  })

  const props = withDefaults(defineProps<Props>(), {
    variant: 'primary',
    size: 'm',
    dot: false,
    uppercase: true,
    rounded: false,
    bold: true,
  })

  interface Props {
    label?: string
    variant?: BadgeVariant
    size?: BadgeSize
    icon?: IconName
    dot?: boolean
    uppercase?: boolean
    rounded?: boolean
    bold?: boolean
  }

  const $style = useCssModule()

  const computedClasses = computed(() => [
    $style[`variant-${props.variant}`],
    $style[`size-${props.size}`],
    $style[props.dot ? 'dot' : 'badge'],
    props.uppercase && $style.uppercase,
    props.rounded && $style.rounded,
    props.bold && $style.bold,
  ])
</script>

<template>
  <div :class="computedClasses">
    <AlgIcon
      v-if="props.icon"
      :name="props.icon"
      size="xs"
    />
    <slot v-if="!label" />
    <template v-else>
      {{ props.label }}
    </template>
  </div>
</template>

<style lang="scss" module>
  .dot {
    position: relative;
    display: inline-flex;
    border: 1px solid var(--alg-color-surface-border);
    border-radius: 100%;

    &.size-xs {
      width: var(--alg-font-size-xxs);
      height: var(--alg-font-size-xxs);
    }

    &.size-s {
      width: var(--alg-font-size-s);
      height: var(--alg-font-size-s);
    }

    &.size-m {
      width: var(--alg-font-size-m);
      height: var(--alg-font-size-m);
    }

    &.size-l {
      width: var(--alg-font-size-l);
      height: var(--alg-font-size-l);
    }

    &.variant-primary {
      background-color: var(--alg-color-text-highlight);
    }

    &.variant-secondary {
      background-color: var(--alg-color-text-secondary);
    }

    &.variant-info {
      background-color: var(--alg-color-state-info);
    }

    &.variant-success {
      background-color: var(--alg-color-state-success);
    }

    &.variant-warning {
      background-color: var(--alg-color-state-warning);
    }

    &.variant-danger {
      background-color: var(--alg-color-state-danger);
    }

    &.variant-purple {
      background-color: var(--alg-color-purple-100);
    }
  }

  .badge {
    position: relative;
    display: inline-flex;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    border-radius: var(--alg-effect-radius-s);
    gap: calc(var(--alg-spacing-xs) / 2);
    padding-inline: var(--alg-spacing-xs);
    white-space: nowrap;

    &.bold {
      font-weight: var(--alg-font-weight-bold);
    }

    &.rounded {
      border-radius: var(--alg-effect-radius-l);
    }

    &.uppercase {
      text-transform: uppercase;
    }

    &.size-s {
      height: var(--alg-font-size-m);
      font-size: var(--alg-font-size-xxs);
      line-height: var(--alg-font-size-m);
    }

    &.size-m {
      height: var(--alg-font-size-l);
      font-size: var(--alg-font-size-xs);
      line-height: var(--alg-font-size-l);
    }

    &.size-l {
      height: var(--alg-font-size-xl);
      font-size: var(--alg-font-size-s);
      line-height: var(--alg-font-size-xl);
    }

    &.variant-primary {
      background-color: var(--alg-color-surface-background-highlight);
      color: var(--alg-color-text-highlight);
    }

    &.variant-secondary {
      background-color: var(--alg-color-light-grey);
      color: var(--alg-color-text-secondary);
    }

    &.variant-info {
      background-color: var(--alg-color-light-blue);
      color: var(--alg-color-state-info);
    }

    &.variant-success {
      background-color: var(--alg-color-light-green);
      color: var(--alg-color-state-success);
    }

    &.variant-warning {
      background-color: var(--alg-color-light-orange);
      color: var(--alg-color-state-warning);
    }

    &.variant-danger {
      background-color: var(--alg-color-light-red);
      color: var(--alg-color-state-danger);
    }

    &.variant-purple {
      background-color: var(--alg-color-light-purple);
      color: var(--alg-color-purple-100);
    }
  }
</style>
