<script setup lang="ts">
  import { AsyncComponentLoader, computed, defineAsyncComponent } from 'vue'

  import { IconType } from '@/types'

  interface Props {
    name: string
    type?: IconType
    size?: number | [number, number]
    color?: string
    flipH?: boolean
    flipV?: boolean
    rotate?: number
    colored?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    type: 'fill',
    size: 24,
    color: 'currentColor',
    flipH: false,
    flipV: false,
    rotate: 0,
    skipSvgo: false,
    colored: false,
  })

  const FALLBACK_ICON = defineAsyncComponent(
    () => import('../../../../resources/assets/svg/material/block.svg?component'),
  )

  const style = computed(() => {
    const rotate = `rotate(${props.rotate}deg)`

    const flipH = `scaleX(${props.flipH ? -1 : 1})`

    const flipV = `scaleY(${props.flipV ? -1 : 1})`

    return {
      transform: `${rotate} ${flipH} ${flipV}`,
    }
  })

  const width = computed(() => (Array.isArray(props.size) ? props.size[0] : props.size))
  const height = computed(() => (Array.isArray(props.size) ? props.size[1] : props.size))

  function getIcon(name: string): AsyncComponentLoader {
    const params = name.split('/')

    if (params.length > 1) {
      return defineAsyncComponent({
        loader: () =>
          import(`../../../../resources/assets/svg/${params[0]}/${params[1]}.svg?component`),
        errorComponent: FALLBACK_ICON,
      })
    } else {
      return defineAsyncComponent({
        loader: () => import(`../../../../resources/assets/svg/${name}.svg?component`),
        errorComponent: FALLBACK_ICON,
      })
    }
  }
</script>

<template>
  <div
    class="svg-icon"
    :style="{ width: `${width}px`, height: `${height}px` }"
  >
    <component
      :is="getIcon(props.name)"
      aria-hidden="true"
      focusable="false"
      :data-name="name"
      :style="style"
      :height="height"
      :width="width"
      :fill="!props.colored ? (props.type === 'fill' ? props.color : 'none') : ''"
      :stroke="!props.colored ? (props.type === 'stroke' ? props.color : 'none') : ''"
    />
  </div>
</template>

<style lang="scss" scoped>
  .svg-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
